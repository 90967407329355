<template>
  <footer>
    <v-row>
      <v-col cols="12" md="6">
        <div class="d-flex align-center">
          <img
            src="../../assets/img/LOGO IPO.png"
            height="150px"
            alt="logo-ipo"
            class="mr-3"
          />
          <div>
            <p class="font-size-weight white--text">
              Ikatan Psikologi Olahraga HIMPSI
            </p>
            <p class="white--text mb-0">
              Alamat : Jl.
              <br />
              <br />
              Telp. : 08xxxxx Email : xxxx@email.com
            </p>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="d-flex align-center justify-end" style="min-height: 100%">
          <v-btn icon class="mr-3" color="white"
            ><v-icon>mdi-facebook</v-icon></v-btn
          >
          <v-btn icon class="mr-3" color="white"
            ><v-icon>mdi-linkedin</v-icon></v-btn
          >
          <v-btn icon class="mr-3" color="white"
            ><v-icon>mdi-instagram</v-icon></v-btn
          >
          <v-btn icon class="mr-3" color="white"
            ><v-icon>mdi-twitter</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
