<template>
  <div class="mission">
    <div class="item_mission">
      <h1 class="white--text txt_mission">Misi Kami</h1>
      <div v-if="!loading">
        <v-carousel
          v-if="dataSet.length"
          hide-delimiters
          class="mt-3"
          show-arrows-on-hover
          height="max-content"
        >
          <v-carousel-item v-for="(item, i) in dataSet" :key="i">
            <div class="white--text txt_mission">
              {{ item.isi }}
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <v-skeleton-loader v-else type="card"></v-skeleton-loader>
      <div style="height: 100px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "misiPage",
  data() {
    return {
      loading: false,
      page: 1,
      limit: 6,
      last_page: null,
      dataSet: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      let data = {
        path: `misi`,
      };
      this.$store
        .dispatch("getApi", data)
        .then((data) => {
          this.dataSet = data.data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
