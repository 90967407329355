<template>
  <div>
    <nav :class="$route.params == 'feeds' ? 'sticky' : 'nav_section'">
      <div class="temp_burger">
        <v-btn
          icon
          @click="showDrawer('open')"
          :color="!isScroll ? 'white' : 'black'"
          ><v-icon>mdi-menu</v-icon></v-btn
        >
      </div>
      <div class="mobile_show">
        <div @click="navigate(path)" class="menu_item_scroll">
          <v-icon>mdi-close</v-icon>
        </div>
        <div
          @click="navigate('/')"
          :class="path == '/' ? 'menu_item_active' : 'menu_item_scroll'"
        >
          BERANDA
        </div>
        <div
          @click="navigate('/about')"
          :class="
            path == '/about' || path == '/sejarah'
              ? 'menu_item_active'
              : 'menu_item_scroll'
          "
        >
          TENTANG KAMI
        </div>
        <div
          @click="navigate('/program')"
          :class="path == '/program' ? 'menu_item_active' : 'menu_item_scroll'"
        >
          PROGRAM
        </div>
        <div
          @click="navigate('/keanggotaan')"
          :class="
            path == '/keanggotaan' ? 'menu_item_active' : 'menu_item_scroll'
          "
        >
          KEANGGOTAAN
        </div>
        <div
          @click="navigate('/feeds')"
          :class="path == '/feeds' ? 'menu_item_active' : 'menu_item_scroll'"
        >
          INFORMASI & PENGUMUMAN
        </div>
      </div>
      <div class="container_menu">
        <div
          @click="navigate('/')"
          :class="
            path == '/'
              ? 'menu_item_active'
              : path == '/feeds'
              ? 'menu_item_scroll'
              : 'menu_item'
          "
        >
          BERANDA
        </div>
        <div
          @click="navigate('/about')"
          :class="
            path == '/about' || path == '/sejarah'
              ? 'menu_item_active'
              : path == '/feeds'
              ? 'menu_item_scroll'
              : 'menu_item'
          "
        >
          TENTANG KAMI
        </div>
        <div
          @click="navigate('/program')"
          :class="
            path == '/program'
              ? 'menu_item_active'
              : path == '/feeds'
              ? 'menu_item_scroll'
              : 'menu_item'
          "
        >
          PROGRAM
        </div>
        <div
          @click="navigate('/keanggotaan')"
          :class="
            path == '/keanggotaan'
              ? 'menu_item_active'
              : path == '/feeds'
              ? 'menu_item_scroll'
              : 'menu_item'
          "
        >
          KEANGGOTAAN
        </div>
        <div
          @click="navigate('/feeds')"
          :class="path == '/feeds' ? 'menu_item_active' : 'menu_item'"
        >
          INFORMASI & PENGUMUMAN
        </div>
      </div>

      <div class="nologo">
        <img src="../assets/img/LOGO IPO.png" height="50px" alt="" />
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path: "",
      isScroll: false,
      drawer: false,
    };
  },
  props: ["bg"],
  created() {
    const vm = this;
    window.addEventListener("scroll", () => {
      let nav = document.querySelector(".nav_section");
      let item = document.querySelectorAll(".menu_item");
      let logo = document.querySelector(".nologo");
      vm.isScroll = window.scrollY > 0 ? true : false;
      nav.classList.toggle("sticky", window.scrollY > 0);
      logo.classList.toggle("logo", window.scrollY > 0);
      item.forEach((el) => {
        el.classList.toggle("menu_item_scroll", window.scrollY > 0);
      });
    });
  },
  mounted() {
    this.path = this.$route.path;
  },
  methods: {
    navigate(path) {
      if (this.path != path) {
        this.$router.push(path);
      }
      this.showDrawer("close");
    },
    showDrawer(state) {
      let draw = document.querySelector(".mobile_show");
      draw.style.top = state == "open" ? "0" : "-100vh";
      draw.style.transition = "0.3s";
    },
  },
  components: {},
};
</script>

<style scoped>
.nologo {
  display: none;
}
.logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding: 0 30px;
}
.nav_section {
  position: fixed;
  display: flex;
  align-content: center;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 5;
  transition: 0.3s;
  height: 70px;
}
.sticky {
  background: #fff;
  transition: 0.3s;
  display: flex;
  align-content: center;
}
.container_menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  width: 60%;
  padding: 0 30px;
  transition: 0.3s;
}
.temp_burger {
  display: none;
}

.menu_item {
  color: #fff;
  cursor: pointer;
}
.menu_item_scroll {
  color: #000;
  cursor: pointer;
}
.menu_item_active {
  color: #ff6d40;
  cursor: pointer;
}
.mobile_show {
  top: -100vh;
  height: 100vh;
  width: 100%;
  background: white;
  position: absolute;
  transition: 0.3s;
}
@media (max-width: 1400px) {
}

@media (max-width: 1200px) {
  .container_menu {
    width: 70%;
  }
}

@media (max-width: 992px) {
  .container_menu {
    display: none;
    overflow: hidden;
  }
  .temp_burger {
    display: flex;
    align-items: center;
    padding: 10px 30px;
  }
  .menu_item_scroll {
    padding: 10px;
  }
  .menu_item_active {
    padding: 10px;
  }
  .menu_item_scroll:hover {
    background: rgba(134, 130, 130, 0.373);
  }
  .menu_item_active:hover {
    background: rgba(134, 130, 130, 0.373);
  }
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
  .content_hero {
    width: 100%;
    padding: 0;
  }

  .mission {
    padding: 0;
  }

  .container_program {
    padding: 100px 10px;
  }
}
</style>
