var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{class:_vm.$route.params == 'feeds' ? 'sticky' : 'nav_section'},[_c('div',{staticClass:"temp_burger"},[_c('v-btn',{attrs:{"icon":"","color":!_vm.isScroll ? 'white' : 'black'},on:{"click":function($event){return _vm.showDrawer('open')}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1),_c('div',{staticClass:"mobile_show"},[_c('div',{staticClass:"menu_item_scroll",on:{"click":function($event){return _vm.navigate(_vm.path)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('div',{class:_vm.path == '/' ? 'menu_item_active' : 'menu_item_scroll',on:{"click":function($event){return _vm.navigate('/')}}},[_vm._v(" BERANDA ")]),_c('div',{class:_vm.path == '/about' || _vm.path == '/sejarah'
            ? 'menu_item_active'
            : 'menu_item_scroll',on:{"click":function($event){return _vm.navigate('/about')}}},[_vm._v(" TENTANG KAMI ")]),_c('div',{class:_vm.path == '/program' ? 'menu_item_active' : 'menu_item_scroll',on:{"click":function($event){return _vm.navigate('/program')}}},[_vm._v(" PROGRAM ")]),_c('div',{class:_vm.path == '/keanggotaan' ? 'menu_item_active' : 'menu_item_scroll',on:{"click":function($event){return _vm.navigate('/keanggotaan')}}},[_vm._v(" KEANGGOTAAN ")]),_c('div',{class:_vm.path == '/feeds' ? 'menu_item_active' : 'menu_item_scroll',on:{"click":function($event){return _vm.navigate('/feeds')}}},[_vm._v(" INFORMASI & PENGUMUMAN ")])]),_c('div',{staticClass:"container_menu"},[_c('div',{class:_vm.path == '/'
            ? 'menu_item_active'
            : _vm.path == '/feeds'
            ? 'menu_item_scroll'
            : 'menu_item',on:{"click":function($event){return _vm.navigate('/')}}},[_vm._v(" BERANDA ")]),_c('div',{class:_vm.path == '/about' || _vm.path == '/sejarah'
            ? 'menu_item_active'
            : _vm.path == '/feeds'
            ? 'menu_item_scroll'
            : 'menu_item',on:{"click":function($event){return _vm.navigate('/about')}}},[_vm._v(" TENTANG KAMI ")]),_c('div',{class:_vm.path == '/program'
            ? 'menu_item_active'
            : _vm.path == '/feeds'
            ? 'menu_item_scroll'
            : 'menu_item',on:{"click":function($event){return _vm.navigate('/program')}}},[_vm._v(" PROGRAM ")]),_c('div',{class:_vm.path == '/keanggotaan'
            ? 'menu_item_active'
            : _vm.path == '/feeds'
            ? 'menu_item_scroll'
            : 'menu_item',on:{"click":function($event){return _vm.navigate('/keanggotaan')}}},[_vm._v(" KEANGGOTAAN ")]),_c('div',{class:_vm.path == '/feeds' ? 'menu_item_active' : 'menu_item',on:{"click":function($event){return _vm.navigate('/feeds')}}},[_vm._v(" INFORMASI & PENGUMUMAN ")])]),_vm._m(0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nologo"},[_c('img',{attrs:{"src":require("../assets/img/LOGO IPO.png"),"height":"50px","alt":""}})])
}]

export { render, staticRenderFns }